<template>
  <v-snackbar
    v-model="snackbar"
    :timeout="timeout"
    :color="color"
    :top="position.top"
    :bottom="position.bottom"
    :left="position.left"
    :right="position.right"
    :centered="position.centered"
  >
    <v-icon v-if="color.includes('error')"> mdi-close-circle-outline </v-icon>
    <v-icon v-else-if="color.includes('warning')">
      mdi-alert-circle-outline
    </v-icon>
    <v-icon v-else-if="color.includes('info')">
      mdi-information-outline
    </v-icon>
    <v-icon v-else-if="color.includes('success')">
      mdi-check-circle-outline
    </v-icon>
    {{ text }}

    <template v-slot:action="{ attrs }">
      <v-btn
        class="mx-2"
        fab
        small
        text
        v-bind="attrs"
        color="danger"
        @click="$emit('close')"
      >
        <v-icon dark> mdi-close </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  props: {
    snackbarActive: {
      type: Boolean,
      default: false,
    },
    text: {
      type: [String, Error],
      default: "",
    },
    timeout: {
      type: Number,
      default: 5000,
    },
    color: {
      type: String,
      default: "",
    },
    position: {
      type: Object,
      default: () => ({
        top: false,
        bottom: false,
        right: false,
        left: false,
        centered: false,
      }),
    },
  },
  watch: {
    snackbarActive: function (value) {
      this.snackbar = value;
    },
    snackbar: function (value) {
      if (!value) this.$emit("close");
    },
  },
  data: () => ({
    snackbar: false,
  }),
});
</script>

<style></style>
