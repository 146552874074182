<template>
  <v-navigation-drawer
    app
    dark
    :permanent="!isMobile"
    color="primary"
    :value="value"
    :fixed="isMobile"
    :temporary="isMobile"
    :mini-variant="miniVariant && !isMobile"
    @input="handlerShowDrawer"
  >
    <v-list>
      <v-list-item href="/" :class="{ 'px-2': miniVariant }">
        <v-list-item-avatar>
          <v-img alt="Foto de perfil" src="/images/logos/logo-circle-white.png">
          </v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="text-h6"> NEGOCIOS </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list nav shaped class="pl-0" dense>
      <template v-for="item in items">
        <v-list-group
          v-if="item.items?.length > 0"
          color="white"
          :key="`lvl1_sub_${item.ref}`"
          :prepend-icon="`mdi-${item.icons.menu}`"
          @click="
            miniVariant
              ? $router.push({ name: item.path || 'Home' })
              : () => null
          "
        >
          <template v-slot:activator>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </template>

          <template v-for="subItem in item.items">
            <v-list-group
              v-if="subItem.items?.length > 0"
              :key="`lvl2_sub_${subItem.ref}`"
              no-action
              sub-group
              color="white"
              :append-icon="`mdi-${subItem.icon}`"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ subItem.name }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="lastItem in subItem.items"
                :key="lastItem.ref"
                :to="{ name: lastItem.path }"
                link
              >
                <v-list-item-icon>
                  <v-icon v-text="`mdi-${lastItem.icon}`"></v-icon>
                </v-list-item-icon>
                <v-list-item-title v-text="lastItem.name"></v-list-item-title>
              </v-list-item>
            </v-list-group>

            <v-list-item
              v-else
              :key="`lvl2_${subItem.ref}`"
              link
              :to="{ name: subItem.path }"
              :disabled="subItem?.disabled ?? false"
            >
              <v-list-item-icon>
                <v-icon :disabled="item.disabled"
                  >mdi-{{ subItem.icons.menu }}</v-icon
                >
              </v-list-item-icon>
              <v-list-item-title class="font-weight-medium">{{
                subItem.name
              }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-list-group>

        <v-list-item
          v-else
          :key="`lvl1_${item.ref}`"
          link
          :to="{ name: item.path }"
          :disabled="item.disabled"
        >
          <v-list-item-icon>
            <v-icon :disabled="item.disabled">mdi-{{ item.icons.menu }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="font-weight-medium">{{
            item.name
          }}</v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
    <template v-slot:append>
      <p class="mb-0 text-center caption white--text font-weight-bold">
        Versión {{ version }}
      </p>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { pages } from "@/router/data/index.js";

export default {
  name: "SideBarMenu",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    miniVariant: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    version() {
      return this.$store.getters.GetVersion;
    },
    items() {
      return pages.filter((e) => e.type == "box");
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
    isTablet() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
  },
  methods: {
    handlerShowDrawer(value) {
      this.$emit("value-change", value);
    },
  },
};
</script>

<style></style>
