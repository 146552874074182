var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('Menu',{attrs:{"miniVariant":_vm.miniVariant},on:{"value-change":function($event){_vm.sidebar = $event}},model:{value:(_vm.sidebar),callback:function ($$v) {_vm.sidebar=$$v},expression:"sidebar"}}),_c('Navbar',{attrs:{"items":_vm.page?.breadcrumb ?? [],"user":_vm.user},on:{"click-burger":function($event){return _vm.handlerClickBurger()}}}),_c('v-main',{staticClass:"secondary lighten-1"},[_c('v-container',{staticClass:"not-extended"},[(!_vm.page.hideTitle)?_c('h2',{staticClass:"text-h4 font-weight-bold primary--text text--darken-4 my-6"},[_vm._v(" "+_vm._s(_vm.page.title)+" ")]):_vm._e(),_c('v-fade-transition',{attrs:{"mode":"out-in"}},[_c('router-view')],1)],1),_c('base-snackbar',{attrs:{"text":_vm.snackbar.text,"snackbar-active":_vm.snackbar.active,"timeout":_vm.snackbar.timeout,"color":_vm.snackbar.color,"position":{
        top: _vm.snackbar.top,
        bottom: _vm.snackbar.bottom,
        right: _vm.snackbar.right,
        left: _vm.snackbar.left,
        centered: _vm.snackbar.centered,
      }},on:{"close":function($event){return _vm.closeSnackbar()}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }