<template>
  <v-app>
    <!-- SIDE BAR MENU -->
    <Menu
      v-model="sidebar"
      :miniVariant="miniVariant"
      @value-change="sidebar = $event"
    ></Menu>
    <!-- SUP NAVBAR -->
    <Navbar
      :items="page?.breadcrumb ?? []"
      :user="user"
      @click-burger="handlerClickBurger()"
    />
    <!-- CONTENT -->
    <v-main class="secondary lighten-1">
      <v-container class="not-extended">
        <!-- PAGE TITLE -->
        <h2
          class="text-h4 font-weight-bold primary--text text--darken-4 my-6"
          v-if="!page.hideTitle"
        >
          {{ page.title }}
        </h2>
        <v-fade-transition mode="out-in">
          <!-- TRANSITION ANIMATION -->
          <router-view />
        </v-fade-transition>
      </v-container>
      <!-- NOTIFICATION -->
      <base-snackbar
        :text="snackbar.text"
        :snackbar-active="snackbar.active"
        :timeout="snackbar.timeout"
        :color="snackbar.color"
        :position="{
          top: snackbar.top,
          bottom: snackbar.bottom,
          right: snackbar.right,
          left: snackbar.left,
          centered: snackbar.centered,
        }"
        @close="closeSnackbar()"
      />
    </v-main>
  </v-app>
</template>

<script>
import Menu from "@/components/Bars/LeftBarMenu";
import Navbar from "@/components/Bars/SupNavBar.vue";
import BaseSnackbar from "@/components/Snackbar/BaseSnackbar.vue";
export default {
  name: "DashboardLayout",
  components: {
    Menu,
    Navbar,
    BaseSnackbar,
  },
  data: () => ({
    sidebar: false,
    miniVariant: false,
  }),
  mounted() {
    if (this.sidebarCache) {
      this.sidebarCache === "open"
        ? (this.miniVariant = false)
        : (this.miniVariant = true);
    } else if (this.$vuetify.breakpoint.mdAndDown && !this.isMobile) {
      this.miniVariant = true;
    }
  },
  computed: {
    page() {
      return this.$store.getters.GetPage;
    },
    snackbar() {
      return this.$store.getters.GetSnackbar;
    },
    user() {
      return this.$store.getters.user;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
    sidebarCache() {
      return localStorage.getItem("sidebar");
    },
  },
  methods: {
    closeSnackbar() {
      this.$store.commit("setSnackbar", { active: false });
    },
    handlerClickBurger() {
      this.setSidebarCache(
        this.isMobile ? this.miniVariant : !this.miniVariant
      );
      this.isMobile
        ? (this.sidebar = !this.sidebar)
        : (this.miniVariant = !this.miniVariant);
    },
    setSidebarCache(value) {
      localStorage.setItem("sidebar", value ? "closed" : "open");
    },
  },
};
</script>

<style></style>
